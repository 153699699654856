@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello.eot?48381373');
  src: url('../fonts/fontello.eot?48381373#iefix') format('embedded-opentype'),
       url('../fonts/fontello.woff2?48381373') format('woff2'),
       url('../fonts/fontello.woff?48381373') format('woff'),
       url('../fonts/fontello.ttf?48381373') format('truetype'),
       url('../fonts/fontello.svg?48381373#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello.svg?48381373#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-star:before { content: '\e800'; } /* '' */
.icon-star-empty:before { content: '\e801'; } /* '' */
.icon-dot-3:before { content: '\e802'; } /* '' */
.icon-plus:before { content: '\e803'; } /* '' */
.icon-cancel:before { content: '\e804'; } /* '' */
.icon-ok:before { content: '\e805'; } /* '' */
.icon-minus:before { content: '\e806'; } /* '' */
.icon-cancel-alt-filled:before { content: '\e807'; } /* '' */
.icon-trash-empty:before { content: '\e808'; } /* '' */
.icon-eye:before { content: '\e809'; } /* '' */
.icon-basket:before { content: '\e80a'; } /* '' */
.icon-pencil:before { content: '\e80b'; } /* '' */
.icon-heart:before { content: '\e80c'; } /* '' */
.icon-heart-empty:before { content: '\e80d'; } /* '' */
.icon-search:before { content: '\e80e'; } /* '' */
.icon-phone:before { content: '\e80f'; } /* '' */
.icon-home-outline:before { content: '\e810'; } /* '' */
.icon-key:before { content: '\e811'; } /* '' */
.icon-location:before { content: '\e812'; } /* '' */
.icon-key-1:before { content: '\e813'; } /* '' */
.icon-compass:before { content: '\e814'; } /* '' */
.icon-cloud:before { content: '\e815'; } /* '' */
.icon-suitcase:before { content: '\e816'; } /* '' */
.icon-flash:before { content: '\e817'; } /* '' */
.icon-ailpay:before { content: '\e818'; } /* '' */
.icon-line:before { content: '\e819'; } /* '' */
.icon-up-small:before { content: '\e81a'; } /* '' */
.icon-basket-1:before { content: '\e81b'; } /* '' */
.icon-calendar:before { content: '\e81c'; } /* '' */
.icon-chat:before { content: '\e81d'; } /* '' */
.icon-bell:before { content: '\e81e'; } /* '' */
.icon-attention:before { content: '\e81f'; } /* '' */
.icon-home:before { content: '\e820'; } /* '' */
.icon-search-1:before { content: '\e821'; } /* '' */
.icon-thumbs-up:before { content: '\e822'; } /* '' */
.icon-user-1:before { content: '\e823'; } /* '' */
.icon-pencil-alt:before { content: '\e824'; } /* '' */
.icon-bell-1:before { content: '\e825'; } /* '' */
.icon-attention-1:before { content: '\e826'; } /* '' */
.icon-info:before { content: '\e827'; } /* '' */
.icon-question:before { content: '\e828'; } /* '' */
.icon-chat-1:before { content: '\e829'; } /* '' */
.icon-phone-1:before { content: '\e82a'; } /* '' */
.icon-cog:before { content: '\e82b'; } /* '' */
.icon-tools:before { content: '\e82c'; } /* '' */
.icon-eye-1:before { content: '\e82d'; } /* '' */
.icon-eye-off:before { content: '\e82e'; } /* '' */
.icon-left-hand:before { content: '\e82f'; } /* '' */
.icon-right-hand:before { content: '\e830'; } /* '' */
.icon-down-open-1:before { content: '\e831'; } /* '' */
.icon-menu:before { content: '\e832'; } /* '' */
.icon-right:before { content: '\e833'; } /* '' */
.icon-email:before { content: '\e834'; } /* '' */
.icon-left-open-1:before { content: '\e835'; } /* '' */
.icon-right-open-1:before { content: '\e836'; } /* '' */
.icon-up-open-1:before { content: '\e837'; } /* '' */
.icon-down-open-mini:before { content: '\e838'; } /* '' */
.icon-left-open-mini:before { content: '\e839'; } /* '' */
.icon-right-open-mini:before { content: '\e83a'; } /* '' */
.icon-up-open-mini:before { content: '\e83b'; } /* '' */
.icon-down-open-big:before { content: '\e83c'; } /* '' */
.icon-left-open-big:before { content: '\e83d'; } /* '' */
.icon-right-open-big:before { content: '\e83e'; } /* '' */
.icon-up-open-big:before { content: '\e83f'; } /* '' */
.icon-globe:before { content: '\e840'; } /* '' */
.icon-down-open:before { content: '\f004'; } /* '' */
.icon-up-open:before { content: '\f005'; } /* '' */
.icon-right-open:before { content: '\f006'; } /* '' */
.icon-left-open:before { content: '\f007'; } /* '' */
.icon-user:before { content: '\f061'; } /* '' */
.icon-users:before { content: '\f064'; } /* '' */
.icon-gplus:before { content: '\f0d5'; } /* '' */
.icon-sort:before { content: '\f0dc'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-instagram-1:before { content: '\f16d'; } /* '' */
.icon-bank:before { content: '\f19c'; } /* '' */
.icon-language:before { content: '\f1ab'; } /* '' */
.icon-wechat:before { content: '\f1d7'; } /* '' */
.icon-handshake-o:before { content: '\f2b5'; } /* '' */
.icon-address-book-o:before { content: '\f2ba'; } /* '' */
.icon-facebook:before { content: '\f300'; } /* '' */
.icon-facebook-rect:before { content: '\f301'; } /* '' */
.icon-twitter-bird:before { content: '\f303'; } /* '' */
.icon-twitter:before { content: '\f309'; } /* '' */
.icon-facebook-1:before { content: '\f30c'; } /* '' */
.icon-instagram:before { content: '\f31e'; } /* '' */