@import "helpers/variables";
@import "helpers/mixin";
@import "bootstrap";
@import "fontello";
@import "animations";
@import "simple-line-icons";
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,400,700|Oswald:300,500&display=swap');

%transition-set {
  @include transition( all 0.5s ease-in-out); }
%text-shadow {
  text-shadow: 0px 1px 8px rgba(#000, 0.4); }
%box-shadow {
  box-shadow: 0px 1px 15px rgba(#000, 0.3); }
%noto-sans-tc {
  font-family: 'Noto Sans TC', sans-serif; }
%Oswald {
  font-family: 'Oswald', sans-serif;
  font-weight: 600; }
%img-ratio {
  overflow: hidden;
  width: 100%;
  height: 0px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0; } }

//
body {
  position: relative;
  background-color: #ffffff;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont,"Source Sans Pro", Roboto, 'Microsoft JhengHei', 'Helvetica Neue', Arial, sans-serif;
  &.modal-open {
    padding-right: 0px !important;
    .mm-page {
      position: initial; } } }
.modal {
  .modal-dialog {
    @include sm-down {
      width: 100%; } } }
a {
  color: $dark;
  text-decoration: none;
  &:hover,&:focus,&:active,&.active {
    color: $primary;
    text-decoration: none;
    outline: 0; }
  &.readmore {
    color: $primary;
    display: flex;
    align-items: center;
    @include sm-down {
      @include font-size(12px); }
    &:hover {
      .icon {
        .arr {
          animation: arrow-left 2s infinite; } } }
    .icon {
      margin-left: .5rem;
      display: flex;
      overflow: hidden;
      width: 31px;
      flex: 0 0 31px;
      @include sm-down {
        transform: scale(.8);
        margin-left: 0; }
      .arr {
        line-height: 19px;
        margin-right: 20px; } } }
  &.link-color {
    color: $primary;
    &:hover,&:focus,&:active,&.active {
      color: $primary;
      text-decoration: underline; } } }
@keyframes arrow-left {
  0% {
    transform: translateX(-100%); }
  40%, 100% {
    transform: translateX(0%); } }

.container {
  position: relative;
  @include md-down {
    max-width: 100%; } }

.bg-black {
  background: #000; }

img {
  &.img-rounded {
    border-radius: 50%; } }
.img-ratio-1by1 {
  @extend %img-ratio;
  padding-bottom: 100%; }
.img-ratio-2by1 {
  @extend %img-ratio;
  padding-bottom: 50%; }
.img-ratio-4by3 {
  @extend %img-ratio;
  padding-bottom: 75%; }
.img-ratio-4by1 {
  @extend %img-ratio;
  padding-bottom: 25%; }
.img-ratio-4by1 {
  @extend %img-ratio;
  padding-bottom: 25%; }

.row {
  &.small-gutters {
    margin-right: -5px;
    margin-left: -5px;
    > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px; } }
  &.middle-gutters {
    margin-right: -10px;
    margin-left: -10px;
    > [class*="col-"] {
      padding-right: 10px;
      padding-left: 10px; } }
  &.large-gutters {
    margin-right: -20px;
    margin-left: -20px;
    > [class*="col-"] {
      padding-right: 20px;
      padding-left: 20px; } } }
.btn {
  border-width: 2px;
  border-radius: 0;
  &.btn-lg {
    @include font-size(17px);
    height: 52px;
    padding: .75rem 1rem; } }

.form-control {
  border-radius: 0;
  border: #ffffff 2px solid;
  &:focus {
    border-color: $primary;
    box-shadow: unset;
    color: $gray-900; }
  &.form-control-lg {
    @include font-size(17px);
    height: 52px;
    padding: .75rem 1rem; } }

.dropdown-menu {
  border: 0;
  margin: 0;
  border-radius: 0;
  @extend %box-shadow;
  a {
    display: block;
    &:hover {
      color: $primary;
      background-color: #ffffff; } } }
.modal-content {
  border-radius: 0 15px 15px 15px;
  border: 0;
  .modal-header {
    background-color: $primary;
    color: darken($primary, 50%);
    border: 0;
    border-radius: 0 15px 0 0;
    padding: .75rem 1.5rem; } }

.pagination-container {
  margin: 20px 0; }
.pagination {
  margin: 0;
  > li {
    > a.page-link {
      display: inline-block;
      border-radius: 5px;
      margin-left: 5px;
      text-align: center;
      @include font-size(14px);
      padding: .5rem .75rem;
      line-height: 14px;
      &:hover ,&:active,&:focus {
        background: $primary;
        border-color: $primary;
        color: #ffffff; } }
    &.actvie {
      > a {
        background: $primary;
        border-color: $primary;
        color: #ffffff; } }
    &.disabled {
      > a {
        &:hover , &:active,&:focus {
          background: lighten( $gray-400, 20%);
          border-color: lighten( $gray-400, 20%); } } } } }
.breadcrumb {
  > li {
    max-width: 200px;
    display: inline-block; } }

%menu-animation {
  transition: all .3s ease-in-out; }
.page-header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  @include sm-down {
    position: relative; }
  > .container {
    @include md-down {
      max-width: 100%;
      padding: 0; } }
  &.is-sticky {
    background-color: #ffffff;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 1003 !important;
    @extend %box-shadow;
    .navbar-brand {
      width: 120px; }
    .navbar-nav {
      .nav-item {
        &:hover {
          .nav-link {
            color: $primary;
            &::before {
              background: $primary; } } }
        &.active {
          .nav-link {
            color: $primary; } }
        .nav-link {
          color: $gray-600; } } } }
  &.white-header {
    background-color: #ffffff;
    position: static; }

  .header-top-alert {
    .alert {
      position: relative;
      padding: .6rem 1rem;
      @include font-size(13px);
      border: 0;
      border-radius: 0;
      background: $primary;
      color: darken($primary,50%);
      text-align: center;
      padding-right: 40px;
      button.close {
        position: absolute;
        right: 1rem;
        @include font-size(15px);
        @include center(y);
        color: darken($primary,50%);
        box-shadow: 0 0 0 rgba(0,0,0,0); } } }
  .navbar {
    padding: 1rem 0;
    @include md-down {
      padding: .75rem 0;
      justify-content: center; }
    .navbar-toggler {
      border: 0;
      @include md-down {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%); } } }
  .navbar-nav {
    @extend %noto-sans-tc;
    font-weight: 400;
    @include font-size(16px);
    .nav-item {
      position: relative;
      &:hover {
        .nav-link {
          color: $primary;
          &::before {
            width: 100%;
            background: #ffffff; }
          &::after {
            border-top-color: $primary;
            z-index: 32;
            position: relative; } } }
      &.active {
        .nav-link {
          color: $primary; } } }
    .nav-link {
      color: $gray-600;
      position: relative;
      z-index: 10;
      padding: .5rem 1rem;
      @include font-size(15px);
      transition: font .1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
      .text {
        z-index: 30;
        position: relative; }
      &::before {
        @extend %menu-animation;
        animation-delay: .2s;
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 0%;
        bottom: 0;
        left: 0;
        // border-radius: $border-radius
        z-index: 0; } }
    .dropdown-menu {
      background: #ffffff;
      // border-radius: 0 $border-radius $border-radius $border-radius
      display: block;
      opacity: 0;
      visibility: hidden;
      @extend %menu-animation;
      box-shadow: 0px 10px 10px rgba(0,0,0,0.3);
      &.show {
        opacity: 1;
        visibility: visible; }
      .dropdown-item {
        color: $gray-800;
        @include font-size(14px);
        padding: .35rem 1rem;
        &:hover,&:focus {
          color: $primary;
          background: transparent; } } } }
  .navbar-brand {
    width: 134px;
    margin-right: 0;
    @include sm-down {
      width: 120px;
      margin: .25rem auto; }
    .branding-image {
      line-height: 0; } } }
section {
  .section-warpper {
    padding: 3rem 0;
    @include sm-down {
      padding: 1.5rem 0; }
    &.pb-2x {
      padding-bottom: 6rem; } } }
hgroup {
  &.section-title-group {
    padding: 0 0 2rem;
    position: relative;
    display: flex;
    align-items: flex-end;
    @include sm-down {
      flex-direction: column;
      align-items: flex-start; }

    &.text-center {
      justify-content: center; }
    &.text-center-break {
      align-items: center;
      flex-direction: column;
      .section-descript {
        &:before {
          display: none; } } }
    &.underline {
      position: relative;
      padding: 0 0 1rem;
      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background: $gray-400;
        position: absolute;
        bottom: 0;
        left: 0; } }
    .section-title {
      text-transform: uppercase;
      position: relative;
      z-index: 12;
      color: $gray-900;
      font-weight: normal;
      @include font-size(38px);
      margin-bottom: 0;
      @include md-down {
        @include font-size(32px); }
      @include sm-down {
        @include font-size(24px); } }
    .section-descript {
      @include font-size(17px);
      position: relative;
      z-index: 9;
      margin-bottom: 0;
      font-size: 300;
      color: $primary;
      &:before {
        content: '/';
        display: inline-block;
        margin: 0 .5rem;
        @include sm-down {
          margin: 0 .25rem; } }
      @include sm-down {
        @include font-size(14px); } } } }

#toTop {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  // border: 2px solid $border-dark
  border-radius: 3px;
  transition: all .3s ease-in-out;
  background: $gray-700;
  z-index: 1981;
  color: #ffffff;
  opacity: 1;
  .icon-up-small {
    @include font-size(20px); }
  &:hover,&:focus,&:active,&.active {
    background: $primary;
    &:before {
      border-color: #FFF; } } }

.slick-slide {
  img {
    width: 100%; } }
.slick-arrow {
  position: absolute;
  top: 50%;
  text-indent: 999px;
  overflow: hidden;
  width: 50px;
  height: 50px;
  margin-top: -35px;
  background: transparent;
  border: 0;
  margin-top: -25px;
  border-radius: 50px;
  z-index: 561;
  &::after {
    content: '';
    display: block;
    border-left: 3px solid #FFF;
    border-bottom: 3px solid #FFF;
    transform: rotate(45deg);
    width: 20px;
    height: 20px;
    position: absolute;
    top: 17px;
    left: 20px; }
  &:hover {
    outline: 0;
    background: #ffffff;
    &::after {
      border-color: #000000; } }
  &:focus {
    outline: 0; }
  &.slick-disabled {
    opacity: 0;
    visibility: hidden; }
  &.slick-prev {
    left: 10px; }
  &.slick-next {
    right: 10px;
    &::after {
      right: 20px;
      left: auto;
      transform: rotate(225deg); } } }
.slick-dots {
  margin: 0;
  text-align: center;
  position: absolute;
  bottom: 30px;
  width: 100%;
  > li {
    display: inline-block;
    &.slick-active {
      > button {
        background: $primary;
        border-color: $primary; } }
    > button {
      width: 12px;
      height: 12px;
      background: transparent;
      border: 2px solid $gray-400;
      border-radius: 50%;
      padding: 0;
      margin: 0 3px;
      text-indent: -9999px;
      overflow: hidden;
      transition: width .2s cubic-bezier(.47,1.64,.41,.8);
      &:hover,&:focus,&:active,&.active {
        outline: 0 !important; } } } }
.main-slider {
  position: relative; }
.hero-banner {
  position: relative;
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  .item {
    position: relative;
    transition: max-width .5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    max-width: 50%;
    @include sm-down {
      flex: 0 0 100%;
      max-width: 100%; }
    &.grow {
      max-width: 70%;
      @include sm-down {
        max-width: 100%;
        margin: 0%; }
      .info-content {
        color: $gray-900;
        width: calc( 1110px / 2 + 290px);
        @include lg-down {
          width: calc( 930px / 2 + 210px); }
        @include md-down {
          width: 100%; }
        @include sm-down {
          width: 100%;
          margin: 0%; } } }
    &.shrink {
      max-width: 30%;
      @include sm-down {
        max-width: 100%; }
      img {
        transform: scale(1.05); }
      .info-content {
        color: $gray-500;
        width: calc( 1110px / 2 - 290px);
        @include lg-down {
          width: calc( 930px / 2 - 200px); }
        @include md-down {
          width: 90%; }
        @include sm-down {
          width: 96%;
          margin: 2%; } } }
    &.left {
      &.shrink {
        .info-content {
          &:after {
            transform: rotate(150deg); } } }
      .info-content {
        right: 0;
        @include sm-down {
          left: 0;
          right: auto; }
        &:after {
          @include md-up {
            content: '';
            display: block;
            width: 1px;
            height: 70%;
            background: $gray-800;
            position: absolute;
            bottom: 15%;
            right: 0;
            transform: rotate(30deg);
            z-index: 98;
            transition: transform .5s cubic-bezier(0.455, 0.03, 0.515, 0.955); } } } }
    .item-img {
      overflow: hidden; }
    img {
      object-fit: cover;
      width: 100%;
      height: calc((1440 / 620)*290px);
      transition: filter .5s cubic-bezier(0.455, 0.03, 0.515, 0.955), transform .1s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      @include sm-down {
        height: 50vh;
        min-height: 400px;
        object-fit: cover; } }
    .info-content {
      position: absolute;
      bottom: 0;
      // width: 80%
      width: calc( 1110px / 2);
      background: #ffffff;
      padding: 1rem 3rem;
      color: $gray-800;
      transition: width .5s ease-out .3s;
      @include lg-down {
        padding: 1rem 2rem;
        width: calc( 960px / 2); }
      @include md-down {
        width: calc( 720px / 2); }
      @include sm-down {
        padding: 1rem;
        width: 96%;
        margin: 2%;
        transition: width .2s ease-out .3s, margin .5s ease-out .3s; }
      .title {
        @include font-size(36px);
        font-weight: 600;
        @include lg-down {
          @include font-size(30px); }
        @include md-down {
          @include font-size(28px); }
        @include sm-down {
          @include font-size(20px); } }
      .desc {
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @include font-size(16px);
        height: 38px;
        line-height: 1.3;
        margin-bottom: 0;
        @include lg-down {
          @include font-size(15px); } } } } }
#footer {
  @include font-size(14px);
  a {
    color: #ffffff; }
  .footer-above {
    padding: 2rem 0; }
  .footer-below {
    padding: 1rem 0;
    @include sm-down {
      @include font-size(11px); } }
  .term-links {
    ul {
      text-align: center;
      margin-bottom: 0;
      margin-top: .8rem;
      > li {
        display: inline-block;
        &:not(:last-child) {
          &:after {
            content: '|';
            display: inline-block;
            margin: 0 6px; } } } } }
  .footer-navi {
    li {
      position: relative;
      display: inline-block;
      &::after {
        content: "|";
        display: inline-block;
        margin: 0 .5rem; } } }
  .area_toggle {
    .nav-tabs {
      border: 0;
      margin-bottom: 1rem; }
    .map-wrapper {
      margin-top: 1rem;
      position: relative;
      overflow: hidden;
      height: 0;
      padding: 0;
      padding-bottom: 50%;
      iframe {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0; } }
    .nav-item {
      &:not(:last-child) {
        margin-right: .25rem; } }
    .nav-link {
      @include font-size(13px);
      border: 0;
      padding: .5rem;
      &:active,&.active {
        color: $gray-600;
        background: $primary; }
      &:hover,&:focus {
        color: $gray-600;
        background: #ffffff; } } }
  .seo-description {
    @include font-size(11px);
    width: 60%; }
  .widget-title {
    @include font-size(16px);
    font-weight: 600;
    margin-bottom: 1rem; }
  .social-wrapper {
    display: flex;
    .social-item {
      &:not(:last-child) {
        margin-right: .8rem; }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        @include font-size(18px);
        width: 24px;
        height: 24px; } } } }

#footer-container {
  background: $gray-700;
  color: rgba(255,255,255,.56);
  @include font-size(13px);
  a {
    color: rgba(255,255,255,.56);
    &:hover {
      color: #ffffff;
      text-decoration: underline; } }
  h6 {
    @include font-size(17px);
    font-weight: normal;
    color: #ffffff; }
  h5 {
    @include font-size(18px);
    font-weight: 600;
    color: #ffffff; }
  ul {
    li {
      &:not(:last-child) {
        margin-bottom: .5rem; } } }
  .footer-logo {
    padding-right: 2rem;
    @include md-down {
      padding-right: 0; } }
  .copyright {
    text-align: center;
    padding: .5rem 0; }

  .info-wrapper {
    display: flex;
    @include font-size(20px);
    font-weight: 300;
    flex-wrap: wrap;
    @include sm-down {
      justify-content: center; }
    h6 {
      @include font-size(20px);
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-right: 2rem;
      @include md-down {
        @include font-size(18px);
        margin-bottom: 1rem; }
      @include sm-down {
        width: 80%; } }
    .icon {
      width: 36px;
      display: inline-block;
      margin-right: .5rem;
      @include md-down {
        width: 28px; } } } }

.card {
  &.card-mission {
    border: 0;
    &:hover {
      .card-img {
        border-radius: 20px;
        .img-bg {
          img {
            transform: scale(1.3);
            opacity: .3; } } } }
    .card-img {
      position: relative;
      background: #40434d;
      overflow: hidden;
      border-radius: 6px;
      transition: border-radius .6s cubic-bezier(0.165, 0.84, 0.44, 1); }
    .img-bg {
      img {
        opacity: .6;
        transition: transform 2s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .5s cubic-bezier(0.165, 0.84, 0.44, 1); } }
    .icon {
      position: absolute;
      width: 60px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 98;
      img {
        -webkit-filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.7));
        filter: drop-shadow(1px 2px 4px rgba(0, 0, 0, 0.7)); } }
    .card-body {
      padding: 1rem 0;
      .card-title {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: .5rem; }
      .card-description {
        font-size: 1rem;
        line-height: 1.4; } } }

  &.card-event {
    border: 0;
    margin-bottom: 1rem;
    @include sm-down {
      margin-bottom: .5rem; }
    &:hover {
      .card-img {
        img {
          transform: scale(1.06); } } }
    &.readmore {
      &:hover {
        .card-img {
          &:after {
            @include sm-up {
              top: 0%;
              opacity: .5;
              visibility: visible; } }
          .btn {
            @include sm-up {
              top: 50%; } } } }
      .card-img {
        @include sm-up {
          position: relative; }
        &:after {
          @include sm-up {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 100%;
            left: 0;
            background: #000000;
            z-index: 1;
            transition: top .5s cubic-bezier(0.165, 0.84, 0.44, 1) ,opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
            opacity: 0;
            visibility: hidden; } }
        .btn {
          display: none;
          @include sm-up {
            position: absolute;
            top: 150%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 8;
            transition: top .5s cubic-bezier(0.165, 0.84, 0.44, 1);
            min-width: 100px;
            display: inline-block; } } } }

    .card-img {
      border-radius: 8px;
      overflow: hidden;
      img {
        transition: all .3s ease-out; } }
    .card-body {
      padding: 12px 0;
      @include font-size(14px);
      @include sm-down {
        text-align: center;
        padding: 8px 0; } }
    .card-title {
      @include font-size(18px);
      font-weight: 600;
      color: $primary;
      margin-bottom: 5px;
      @include sm-down {
        @include font-size(14px); } }
    .date {
      color: $gray-900;
      @include sm-down {
        @include font-size(12px); } } } }

.homeservce-card {
  display: flex;
  background: $gray-100;
  flex-wrap: wrap;
  @include sm-down {
    margin-right: -15px;
    margin-left: -15px; }
  .card-imgage {
    flex: 0 0 62%;
    max-width: 62%;
    @include md-down {
      flex: 0 0 100%;
      max-width: 100%; } }
  .card-content {
    flex: 0 0 38%;
    max-width: 38%;
    padding: 1rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include md-down {
      padding: 1rem;
      flex: 0 0 100%;
      max-width: 100%;
      @include sm-down {
        @include font-size(13px); } }
    h3 {
      @include font-size(24px);
      @include md-down {
        @include font-size(20px); }
      @include sm-down {
        @include font-size(18px); } } } }
@keyframes process-step1 {
  0% {
    transform: translate(25%, -50%);
    opacity: 0; }
  20%, 80% {
    transform: translate(50%, -50%);
    opacity: 1; }
  100% {
    transform: translate(75%, -50%);
    opacity: 0; } }
@keyframes process-step2 {
  0%, 20% {
    transform: translate(25%, -50%);
    opacity: 0; }
  40%, 80% {
    transform: translate(50%, -50%);
    opacity: 1; }
  100% {
    transform: translate(75%, -50%);
    opacity: 0; } }
@keyframes process-step2-sm {
  0%, 20% {
    transform: rotate(90deg) translate(25%, 25%);
    opacity: 0; }
  40%, 80% {
    transform: rotate(90deg) translate(50%, 25%);
    opacity: 1; }
  100% {
    transform: rotate(90deg) translate(75%, 25%);
    opacity: 0; } }
@keyframes process-step3 {
  0%, 40% {
    transform: translate(25%, -50%);
    opacity: 0; }
  60%, 80% {
    transform: translate(50%, -50%);
    opacity: 1; }
  100% {
    transform: translate(75%, -50%);
    opacity: 0; } }
@keyframes process-step3-sm {
  0%, 40% {
    transform: rotate(180deg) translate(25%, -50%);
    opacity: 0; }
  60%, 80% {
    transform: rotate(180deg) translate(50%, -50%);
    opacity: 1; }
  100% {
    transform: rotate(180deg) translate(75%, -50%);
    opacity: 0; } }
.solutions-row {
  &.process-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .solution-item {
      width: 25%;
      position: relative;
      @include sm-down {
        width: 50%; }
      &:not(:last-child) {
        &:after {
          content: '';
          display: block;
          width: 1px;
          height: 70%;
          position: absolute;
          top: 15%;
          right: -1px;
          border-right: 1px dashed #D5D5D5; } }
      &.col-1w {
        flex-grow: 1; }
      &.col-2w {
        flex-grow: 3;
        @include sm-down {
          flex-grow: 1; } }
      &.arrow {
        &:before {
          content: '';
          display: block;
          width: 36px;
          height: 36px;
          position: absolute;
          top: 50%;
          right: 0%;
          transform: translate(50%, -50%);
          z-index: 81; }
        &.arrow-left {
          &:before {
            background: #ffffff url('../images/arrow-proceess-left.svg') no-repeat 50% 50%;
            background-size: 80% 80%; } }
        &.arrow-right {
          &:before {
            background: #ffffff url('../images/arrow-proceess-right.svg') no-repeat 50% 50%;
            background-size: 80% 80%; } }
        &.step1 {
          &:before {
            animation: process-step1 2s infinite; } }
        &.step2 {
          padding-bottom: 2rem;
          &:before {
            animation: process-step2 2s infinite;
            @include sm-down {
              animation: process-step2-sm 2s infinite;
              top: unset;
              bottom: 0;
              left: 50%; } }
          &:after {
            @include sm-down {
              width: 70%;
              height: 1px;
              top: unset;
              bottom: -1px;
              right: 15%;
              border-left: 0;
              border-bottom: 1px dashed #D5D5D5; } } }
        &.step3 {
          @include sm-down {
            order: 3; }
          &:before {
            animation: process-step3 2s infinite;
            @include sm-down {
              animation: process-step3-sm 2s infinite;
              left: 0%; } }
          &:after {
            @include sm-down {
              right: unset;
              left: -1px; } } } }
      &.step4 {
        &:after {
          @include sm-down {
            content: '';
            display: block;
            width: 70%;
            height: 1px;
            position: absolute;
            top: -2rem;
            right: 15%;
            border-top: 1px dashed #D5D5D5; } } } } }
  .solution-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include sm-down {
      margin-bottom: 2rem; } }
  ul {
    &.two-colume {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 50%;
        @include sm-down {
          width: 100%; } } } }

  .item-icon {
    width: 80%;
    max-width: 108px;
    margin: 0 auto 1rem;
    @include md-down {
      max-width: 98px; }
    @include sm-down {
      max-width: 68px; } }
  .item-body {
    display: flex;
    justify-content: center;
    width: 80%;
    @include font-size(15px);
    color: $gray-600;
    margin-bottom: 1.5rem;
    @include sm-down {
      margin-bottom: .5rem;
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem; }
    .name {
      @include font-size(20px);
      font-weight: 600;
      color: $gray-800;
      text-align: center;
      border-bottom: 2px solid #46377b;
      padding-bottom: .8rem;
      margin-bottom: .8rem;
      @include md-down {
        @include font-size(18px); }
      @include sm-down {
        @include font-size(16px); } }
    ul {
      li {
        @include sm-down {
          @include font-size(15px); }
        &:not(:last-child) {
          margin-bottom: .5rem;
          @include sm-down {
            margin-bottom: .25rem; } } } } } }
.home-location {
  position: relative;
  width: 100%;
  z-index: 671;
  @include md-down {
    height: 100%; }
  .googlemap-container {
    height: calc(100vw / 2.5);
    @include lg-down {
      height: calc(100vw / 2); }
    @include md-down {
      height: 420px; } }
  .desc {
    margin-bottom: 1rem; }
  .section-title-group {
    .section-title {
      color: #ffffff; }
    .section-descript {
      color: $warning; } }
  .map-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    background: linear-gradient(45deg, #222328, #636A7A);
    background: -moz-linear-gradient(45deg, #222328, #636A7A);
    background: -webkit-linear-gradient(45deg, #222328, #636A7A);
    background: -o-linear-gradient(45deg, #222328, #636A7A); }
  .localion-content {
    padding: 1.5rem;
    .card-local {
      .title {
        padding-top: 1rem;
        margin-bottom: 1rem;
        font-weight: normal;
        color: $warning;
        @include font-size(24px);
        @include md-down {
          @include font-size(20px); }
        @include sm-down {
          @include font-size(18px); } }
      .info {
        > li {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            margin-bottom: .5rem; } } }
      .icon {
        width: 20px;
        margin-right: .8rem; } } }
  // .twmap-sliders-wraper
  //   margin-top: 2rem
  // .loaction-container
  //   display: flex
  //   .twmap
  //     flex: 0 0 50%
  //     max-width: 50%
  //     padding-right: 5%
  //     +sm-down
  //       display: none
  //     .map-img
  //       margin-top: 3rem
  //   .twmap-content
  //     flex: 0 0 50%
  //     max-width: 50%
  //     padding-top: 5rem
  //     padding-left: 5%
  //     +md-down
  //       padding-top: 2rem
  //     +sm-down
  //       flex: 0 0 100%
  //       max-width: 100%
  //       padding: 3rem 1rem 6rem
  // .card-twmap
  //   margin: 0 .25rem
  //   .card-content
  //     background: #ffffff
  //     padding: 1rem .25rem
  //     .title
  //       +font-size(16px)
  //       text-align: center
  //       color: $gray-900
  // .slick-arrow
  //   &.slick-prev
  //     +md-up
  //       left: -80px
  // .slick-dots
 }  //   bottom: -60px
.home-about {
  margin-top: 4rem;
  .section-title-group {
    @include sm-down {
      padding: 0 0 .5rem; } }
  .home-about-wrapper {
    display: flex;
    flex-direction: column;
    @include sm-down {
      margin-bottom: 2rem; } }
  .home-about-content {
    background: #ffffff;
    padding: 1.5rem;
    width: 48.3%;
    @include md-up {
      position: absolute;
      top: 0;
      right: 15px;
      width: 48.6%;
      padding: 2rem 3rem; }
    @include sm-down {
      padding: 1rem 0;
      width: auto; }
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 45px 45px 0;
      border-color: transparent $primary transparent transparent;
      position: absolute;
      top: 0;
      right: 0;
      @include sm-down {
        right: 15px; } }
    .desc {
      margin-bottom: 1rem; } } }
.home-security {
  .bg-light {
    @include sm-down {
      background: #ffffff !important;
      margin-bottom: 2rem; } }
  .home-sec-content {
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include sm-down {
      padding:  1rem 0; }
    .section-title-group {
      @include sm-down {
        padding: 0 0 .5rem; } }
    .desc {
      margin-bottom: 2rem; } } }

.home-customize {
  margin-top: 4rem;
  border-top: 1px solid $gray-200;
  .card-customize {
    &:hover {
      box-shadow: 0 2px 20px rgba(0,0,0,.2);
      .card-img {
        .photo {
          transform: scale(1.1);
          &:after {
            top: 100%; } }
        .icon {
          top: 100px;
          opacity: 0; } } }
    .card-img {
      position: relative;
      overflow: hidden;
      .photo {
        @extend %img-ratio;
        padding-bottom: 120%;
        position: relative;
        transition: transform .5s cubic-bezier(0.165, 0.84, 0.44, 1);
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          background: #000000;
          opacity: .6;
          top: 0;
          left: 0;
          transition: top .5s cubic-bezier(0.165, 0.84, 0.44, 1); } }
      .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 15%;
        max-width: 80px;
        transition: top .5s cubic-bezier(0.165, 0.84, 0.44, 1), opacity .5s cubic-bezier(0.165, 0.84, 0.44, 1);
        > img {
          width: 100%; } } }
    .card-body {
      .title {
        @include font-size(18px);
        font-weight: 600; }
      .desc {
        @include font-size(15px);
        text-overflow: ellipsis;
        white-space: normal;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 38px;
        line-height: 1.3;
        margin-bottom: 0;
        @include lg-down {
          @include font-size(13px);
          height: 30px;
          line-height: 1.3; } } } } }

.contact-infomation {
  background: $gray-500;
  color: #ffffff;
  .contact-above {
    background: $gray-600;
    padding: 2rem 0;
    h5 {
      @include font-size(24px);
      margin-bottom: 1rem; }
    .form-group {}

    .col-input {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      @include md-down {
        flex: 0 0 50%;
        max-width: 50%; }
      @include sm-down {
        flex: 0 0 100%;
        max-width: 100%; }
      &:nth-child(3) {
        @include md-down {
          flex: 0 0 100%;
          max-width: 100%; } }
      &:nth-child(4) {
        flex: 0 0 66.6666%;
        max-width: 66.6666%;
        @include md-down {
          flex: 0 0 100%;
          max-width: 100%; }
        .form-control.form-control-lg {
          @include md-down {
            height: auto; } } } }

    .col-submit {
      flex: 0 0 33.3333%;
      max-width: 33.3333%; } }
  .contact-below {
    padding: 2rem 0;
    .info-wrapper {
      display: flex;
      @include font-size(20px);
      font-weight: 300;
      flex-wrap: wrap;
      @include sm-down {
        justify-content: center; } }
    h6 {
      @include font-size(20px);
      font-weight: 300;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-right: 2rem;
      @include md-down {
        @include font-size(18px);
        margin-bottom: 1rem; }
      @include sm-down {
        width: 80%; } }
    .icon {
      width: 36px;
      display: inline-block;
      margin-right: .5rem;
      @include md-down {
        width: 28px; } } } }

.kv-banner {
  .col-kv-image {
    flex: 0 0 66%;
    max-width: 66%;
    @include md-down {
      flex: 0 0 50%;
      max-width: 50%; }
    @include sm-down {
      flex: 0 0 100%;
      max-width: 100%; }
    .image {
      @include md-up {
        height: 100%; }
      img {
        @include md-up {
          height: 100%;
          object-fit: cover; }
        @include sm-down {
          min-height: 42vh;
          object-fit: cover; } } } }
  .col-kv-content {
    flex: 0 0 34%;
    max-width: 34%;
    background-color: $gray-500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 3rem;
    color: #ffffff;
    @include font-size(15px);
    @include md-down {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 1rem;
      @include font-size(14px); }
    @include sm-down {
      flex: 0 0 100%;
      max-width: 100%; }
    h2 {
      font-weight: 600;
      @include font-size(28px);
      margin-bottom: 1.5rem;
      @include md-down {
        @include font-size(24px); }
      @include sm-down {
        font-weight: 400;
        margin-bottom: .75rem; } }
    h4 {
      font-weight: normal;
      @include font-size(18px);
      margin-bottom: .3rem;
      @include md-down {
        @include font-size(16px); } }
    ul {
      li {
        &:not(:last-child) {
          margin-bottom: 1rem; } } }
    .desc {
      color: #B9C1C3;
      margin-bottom: 0; } } }

.wmslist-section {
  .wms-listgroup {
    .list-content {
      @include font-size(14px);
      color: $gray-600;
      h5 {
        color: $gray-800;
        font-weight: 600;
        @include font-size(18px); } }
    .list-group-item {
      border: 0;
      padding-left: 0;
      padding-right: 0;
      align-items: center;
      cursor: pointer;
      @include sm-down {
        align-items: flex-start; }
      &.active {
        background: #ffffff;
        .list-icon {
          .icon {
            transform: scale(1.2);
            background: $primary; } }
        .list-content {
          p {
            overflow: initial;
            text-overflow: initial;
            white-space: initial; } } } }
    .list-content {
      flex: 1;
      min-width: 0;
      p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include sm-down {
          overflow: initial;
          text-overflow: initial;
          white-space: initial; } } }
    .list-icon {
      margin-right: 1rem;
      .icon {
        width: 66px;
        height: 66px;
        background: $gray-300;
        border-radius: 50%;
        padding: .9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .2s transform ease-in-out;
        img {
          width: 100%; } } } } }
.breadcrumb-section {
  nav {
    @include lg-up {
      position: absolute;
      top: 1rem;
      transform: translateY(-100%);
      width: 100%; }
    .breadcrumb {
      margin-bottom: 0;
      padding-bottom: 1rem;
      @include font-size(14px);
      background: #ffffff;
      color: $gray-500;
      .breadcrumb-item {
        &.active {
          color: $gray-500; } }
      @include md-down {
        padding-left: 0; }
      a {
        color: $gray-900;
        font-weight: 600; } } }
  .breadcrumb-wrapper {
    position: relative;
    min-height: 362px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2rem;
    background: transparent;
    overflow: hidden;
    .breadcrumb-heading {
      color: #ffffff;
      text-align: center;
      text-shadow: 1px 2px 6px rgba(0,0,0,.5);
      h2 {
        @include font-size(56px);
        @extend %Oswald;
        font-weight: 300;
        margin-bottom: .5rem;
        &:after {
          content: '';
          display: block;
          width: 80px;
          height: 3px;
          background-color: $primary;
          margin: .25rem auto 0 auto; } }
      h3 {
        @include font-size(20px);
        @extend %Oswald;
        font-weight: 400; } } } }

.static-page-content {
  .inner-content {
    color: $gray-900;
    @include font-size(16px);
    line-height: 1.8;
    h1,h2,h3,h4,h5,h6 {
      font-weight: 600;
      color: $gray-900; }
    p {
      margin-bottom: 1rem;
      @include md-up {
        margin-bottom: 1.5rem; } }
    img {
      max-width: 100%;
      display: block;
      margin-bottom: 1rem;
      @include md-up {
        margin-bottom: 1.5rem; }
      &.w-md-50 {
        @include md-up {
          width: 50%;
          display: block; } } } } }

.location-lists-container {
  .lists-group {
    &:not(:last-child) {
      margin-bottom: 1rem; } }
  .item-img {
    @include sm-down {
      padding-bottom: 100%;
      overflow: hidden;
      width: 100%;
      height: 0px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0; } }
    @include xs-down {
      overflow: visible;
      width: 100%;
      height: auto;
      padding-bottom: 0;
      margin-bottom: 1rem;
      img {
        height: auto;
        object-fit: unset;
        position: static; } } }
  .item-content {
    h5 {
      @include font-size(20px);
      font-weight: 600;
      color: $gray-900; }
    p {
      @include font-size(14px); } } }
.card-process {
  &.mb-col {
    margin-bottom: 10px; }
  &.primary-outline {
    .card-body {
      padding: 1rem 2rem;
      border: 2px solid $primary;
      background: $gray-100;
      display: flex;
      align-items: center;
      justify-items: center;
      height: 100%; } }
  .card-body {
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-items: center;
    height: 100%;
    @include font-size(15px);
    @include sm-down {
      padding: 2rem 1rem; }
    h3 {
      @include font-size(24px);
      font-weight: 600;
      color: $gray-900; }
    &.bg-gray {
      background: $gray-600;
      color: #ffffff;
      h3 {
        color: #ffffff; } } } }

.page-description {
  @include font-size(15px);
  h4 {
    @include font-size(20px);
    font-weight: 600;
    color: $gray-900; } }
.parallax-images {
  height: 60vh; }

.more-service-wrapper {
  position: relative;
  .mser-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
  .more-service-card {
    background: #595F6E;
    color: #B9C1C3;
    padding: 2rem;
    h3 {
      color: #ffffff;
      @include font-size(28px);
      margin-bottom: 1rem; } } }

.mm-menu {
  &.mm-theme-dark {
    background: $gray-700;
    .mm-listview {
      .item,.subitem {
        &.active {
          color: $warning;
          background: darken($gray-700, 5%); } } } } }

@import url('https://fonts.googleapis.com/css?family=Pathway+Gothic+One');
$timeline-width: 700px;
$timeline-container-width: 100%;
.about-timeline-section {}

.timeline {
  display: flex;
  margin: 0 auto;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: $timeline-width;
  position: relative;
  &__content {
    &-title {
      font-weight: normal;
      font-size: 66px;
      margin: -10px 0 0 0;
      transition: .4s;
      padding: 0 10px;
      box-sizing: border-box;
      @extend %Oswald;
      color: #fff; }
    &-desc {
      margin: 0;
      font-size: 15px;
      box-sizing: border-box;
      color: rgba(#fff, .7);
      font-weight: normal;
      line-height: 25px; } }
  &:before {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
    content: "";
    background: rgba(255, 255, 255, .07);
    @include md-down {
      left: 40px; } }
  &-item {
    padding: 40px 0 60px;
    // opacity: .8
    transition: .5s;
    box-sizing: border-box;
    width: calc(50% - 40px);
    display: flex;
    position: relative;
    transform: translateY(-80px);
    &:before {
      content: attr(data-text);
      letter-spacing: 3px;
      width: 100%;
      position: absolute;
      color: #ffffff;
      font-size: 16px;
      @extend %Oswald;
      border-left: 2px solid rgba(#fff, .5);
      top: 70%;
      margin-top: -5px;
      padding-left: 15px;
      opacity: 0;
      right: calc(-100% - 39px); }
    &:nth-child(even) {
      align-self: flex-end;
      &:before {
        right: auto;
        text-align: right;
        left: calc(-100% - 39px);
        padding-left: 0;
        border-left: none;
        border-right: 2px solid rgba(#fff, .5);
        padding-right: 15px; } }
    &--active {
      opacity: 1;
      transform: translateY(0);
      &:before {
        top: 50%;
        transition: .3s all .2s;
        opacity: 1; }
      .timeline__content-title {
        margin: -50px 0 20px 0;
        text-shadow: 2px 4px 9px rgba(0,0,0,.6);
        @extend %Oswald; }
      .timeline__img {
        border-radius: 12px; } }
    @include md-down {
      align-self: baseline !important;
      width: 100%;
      padding: 0 30px 150px 80px;
      &:before {
        left: 10px !important;
        padding: 0 !important;
        top: 50px;
        text-align: center !important;
        width: 60px;
        border: none !important; }
      &:last-child {
        padding-bottom: 40px; } } }
  &__img {
    max-width: 100%;
    box-shadow: 0 10px 15px rgba(0, 0, 0, .4); }
  &-container {
    width: $timeline-container-width;
    position: relative;
    padding: 80px 0;
    transition: .3s ease 0s;
    background-attachment: fixed;
    background-size: cover;
    background-image: url('../images/history-1.jpg');
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: .8;
      background: linear-gradient(45deg, #222328, #636A7A);
      background: -moz-linear-gradient(45deg, #222328, #636A7A);
      background: -webkit-linear-gradient(45deg, #222328, #636A7A);
      background: -o-linear-gradient(45deg, #222328, #636A7A);
      content: ""; } }
  &-header {
    width: 100%;
    text-align: center;
    margin-bottom: 80px;
    position: relative;
    &__title {
      color: #fff;
      font-size: 46px;
      font-weight: normal;
      margin: 0; }
    &__subtitle {
      color: rgba(255, 255, 255, .5);
      font-size: 16px;
      letter-spacing: 5px;
      margin: 10px 0 0 0;
      font-weight: normal;
      @extend %Oswald; } } }


