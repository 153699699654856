$gray-900:        #000000;
$gray-800:        #242525;
$gray-700:        #40434D;
$gray-600:        #595F6E;
$gray-500:        #606676;
$gray-400:        #9498A0;
$gray-300:        #e9e7e7;
$gray-200:        #ECECEC;
$gray-100:        #F3F5F9;

$primary:         #FF6607;
$success:         #7ac527;
$info:            #21A0C1;
$warning:         #FFE200;
$danger:          #F3273C;
$facebook:        #3b5998;
$twitter:         #00d0ff;
$youtube:         #ff0000;

$border-radius:   0px;